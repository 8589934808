<template>
  <div class="row">
    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="교육대상자 정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <q-chip
            v-for="(item, idx) in items"
            :key="idx"
            :color="customColor(item, item.attendeesTypeCd)"
            text-color="white"
            icon="person_outline"
            :label="item.attendeesPositionName"
            :title="item.attendeesPositionName"
          >
          </q-chip>
        </template>
      </c-card>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="내부 직원"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :usePaging="false"
        :merge="grid.merge"
        :isExcelDown="false"
        :hideBottom="true"
        noDataLabel="교육대상자가 없습니다."
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem('DEPT')" />
            <c-btn
              v-if="editable && !disabled && grid.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave1"
              :param="grid.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveEducation1"
              @btnCallback="saveCallback1" 
            />
            <c-btn v-if="editable && !disabled && grid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table4"
        title="협력업체 직원"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :checkClickFlag="false"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :hideBottom="true"
        noDataLabel="교육대상자가 없습니다."
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem4" />
            <c-btn
              v-if="editable && !disabled && grid2.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave2"
              :param="grid2.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveEducation2"
              @btnCallback="saveCallback2" 
            />
            <c-btn v-if="editable && !disabled && grid2.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem4" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-target-user',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduCourseId: '',
        documentStatusCd: '',
        eduAttendeeList: [],
        educationTime: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:30%',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:30%',
            label: '직책',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            style: 'width:40%',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '360px'
      },
      grid2: {
        columns: [
          {
            name: 'companyName',
            field: 'companyName',
            label: '소속회사',
            align: 'center',
            style: 'width:25%',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:25%',
            label: '직책',
            type: 'text',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            type: 'text',
            style: 'width:25%',
            align: 'center',
            sortable: false
          },
          {
            name: 'residentNo',
            field: 'residentNo',
            label: '생년월일',
            type: 'date',
            style: 'width:25%',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '360px'
      },
      editable: true,
      getUrl: '',
      targetClassCd: '',
      updateMode: false,
      items: [],

      saveUrl: 'transactionConfig.sop.edu.plan.insert.url',
      saveType: 'POST',
      isSave1: false,
      isSave2: false,
      updateUrl: '',
      insertUrl: '',
      deleteUrl: '',
    };
  },
  computed: {
    disabled() {
      return Boolean(this.tabParam.documentStatusCd) && this.tabParam.documentStatusCd !== 'ESC000001'
    },
  },
  watch: {
    'grid.data'() {
      this.tabParam.eduAttendeeList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
    'grid2.data'() {
      this.tabParam.eduAttendeeList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.edu.attendee.get.url;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.targetUser.url;
      this.deleteUrl = transactionConfig.sop.edu.plan.delete.targetUser.url;
      // code setting
      console.log(this.tabParam.educationTime)
      this.getDetail();
      // list setting
    },
    getDetail() {
      if (this.tabParam.eduEducationId) {
        this.grid.data = [];
        this.grid2.data = [];
        this.$_.forEach(this.tabParam.eduAttendeeList, _item => {
          if (_item.attendeesTypeCd === 'EATC00004') {
            this.grid2.data.push(_item) // 부서
          } else {
            this.grid.data.push(_item) // 선임
          }
        })
      }
      if (this.tabParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.tabParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.items = _result.data
          this.updateMode = true;
        },);
      }
    },
    addItem(data) {
      if (data === 'DEPT') this.targetClassCd = '1';
      else this.targetClassCd = '4';

      if (this.targetClassCd === '4') {
        this.grid2.data.splice(0, 0, {
          eduCourseId: this.tabParam.eduCourseId,
          attendeesNo: uuidv4(),
          eduEducationId: this.tabParam.eduEducationId,
          attendeesTypeCd: 'EATC00004',
          attendeesTypeName: '',
          attendeesPositionName: '',
          companyName: '',
          residentNo: '',
          attendanceTime: this.tabParam.educationTime,
          attendeesName: '',
          educationCycle: '',
          editFlag: 'C'
        })
      } else {
        this.popupOptions.title = "내부대상자 검색"; // 교육대상자 검색
        this.popupOptions.param = {
          type: 'multiple',
          isUserAll: true,
        };
        this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`)
        this.popupOptions.visible = true;
        this.popupOptions.width = '50%';
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeTargetUserPopup;
      }
    },
    closeTargetUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
           let index = this.$_.findIndex(this.grid.data, {
            attendeesId: _item.userId,
          });
          // 부서 EATC00003
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              eduCourseId: this.tabParam.eduCourseId,
              attendeesNo: uuidv4(),
              attendeesTypeCd: 'EATC00003',
              eduEducationId: this.tabParam.eduEducationId,
              attendeesTypeName: '',
              attendeesPositionName: _item.jobName,
              attendanceTime: this.tabParam.educationTime,
              attendeesDeptName: _item.deptName,
              seniorityName: _item.seniorName,
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              editFlag: 'C'
            })
          }
            // 직책 EATC00001
        })
      }
    },  
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, { attendeesNo: item.attendeesNo })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addItem4() {
      this.grid2.data.splice(0, 0, {
        eduCourseId: this.tabParam.eduCourseId,
        attendeesNo: uuidv4(),
        eduEducationId: this.tabParam.eduEducationId,
        attendeesTypeCd: 'EATC00004',
        attendeesPositionCd: '',
        attendeesPositionName: '',
        companyName: '',
        residentNo: '',
        attendanceTime: this.tabParam.educationTime,
        attendeesName: '',
        editFlag: 'C'
      })
    },
    removeItem4() {
      let selectData = this.$refs['table4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, { attendeesNo: item.attendeesNo })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveEducation1() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid.data, item => {
            item.regUserId = this.$store.getters.user.userId
            item.chgUserId = this.$store.getters.user.userId
          })
          
          this.isSave1 = !this.isSave1;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveEducation2() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid2.data, item => {
            item.regUserId = this.$store.getters.user.userId
            item.chgUserId = this.$store.getters.user.userId
          })
          
          this.isSave2 = !this.isSave2;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback1() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.grid.data, item => {
        item.editFlag = 'U'
      })
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.grid2.data, item => {
        item.editFlag = 'U'
      })
    },
    innerBtnClicked4(col, props) {
      this.grid2.data.splice(props.rowIndex, 0, {
        key: props.row.key, 
        eduCourseId: this.tabParam.eduCourseId,
        attendeesNo: uuidv4(),
        attendeesTypeCd: 'EATC00004',
        eduEducationId: this.tabParam.eduEducationId,
        attendeesPositionCd: '',
        attendeesPositionName: props.row.attendeesPositionName,
        attendeesName: '',
        companyName: props.row.companyName,
        residentNo: props.row.residentNo,
        editFlag: 'C',
      });
    },
    customColor(data, type) {
      if (type === 'EATC00001') {
        return 'primary';
      } else if (type === 'EATC00002') {
        return 'red';
      } else if (type === 'EATC00003') {
        return 'orange';
      } else {
        return 'green';
      }
    }
  }
};
</script>
